<template>
<!--  <div class="overlay open" @click="close"></div>-->
<!--  <div class="modalCompany">-->
<!--    <div class="close" @click="close">-->
<!--      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path-->
<!--          d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"-->
<!--          fill="#052C57" />-->
<!--      </svg>-->
<!--    </div>-->
  <Modal
    id="AddEmployee"
    modal-size="modal-lg"
    :showFooter="true"
    :showHeader="true"
  >
    <div class="title">
      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.7 3.2C7.28176 3.2 3.7 6.78176 3.7 11.2V36.8C3.7 41.2182 7.28176 44.8 11.7 44.8H37.3C41.7182 44.8 45.3 41.2182 45.3 36.8V19.2C45.3 18.3163 46.0163 17.6 46.9 17.6C47.7837 17.6 48.5 18.3163 48.5 19.2V36.8C48.5 42.9856 43.4856 48 37.3 48H11.7C5.51441 48 0.5 42.9856 0.5 36.8V11.2C0.5 5.01441 5.51441 0 11.7 0H29.3C30.1837 0 30.9 0.716344 30.9 1.6C30.9 2.48366 30.1837 3.2 29.3 3.2H11.7Z"
          fill="#0349A8" />
        <path
          d="M34.1 7.2C34.1 6.31634 34.8163 5.6 35.7 5.6H39.7V1.6C39.7 0.716344 40.4163 0 41.3 0C42.1837 0 42.9 0.716344 42.9 1.6V5.6H46.9C47.7837 5.6 48.5 6.31634 48.5 7.2C48.5 8.08368 47.7837 8.8 46.9 8.8H42.9V12.8C42.9 13.6837 42.1837 14.4 41.3 14.4C40.4163 14.4 39.7 13.6837 39.7 12.8V8.8H35.7C34.8163 8.8 34.1 8.08368 34.1 7.2Z"
          fill="#0349A8" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.1 16C18.1 12.4654 20.9654 9.6 24.5 9.6C28.0346 9.6 30.9 12.4654 30.9 16C30.9 19.5346 28.0346 22.4 24.5 22.4C20.9654 22.4 18.1 19.5346 18.1 16ZM24.5 12.8C22.7327 12.8 21.3 14.2327 21.3 16C21.3 17.7673 22.7327 19.2 24.5 19.2C26.2673 19.2 27.7 17.7673 27.7 16C27.7 14.2327 26.2673 12.8 24.5 12.8Z"
          fill="#0349A8" />
        <path
          d="M11.7 35.2C11.7 29.0144 16.7144 24 22.9 24H26.1C32.2856 24 37.3 29.0144 37.3 35.2V36.8C37.3 37.6837 36.5837 38.4 35.7 38.4C34.8163 38.4 34.1 37.6837 34.1 36.8V35.2C34.1 30.7818 30.5182 27.2 26.1 27.2V32C26.1 32.8837 25.3837 33.6 24.5 33.6C23.6163 33.6 22.9 32.8837 22.9 32V27.2C18.4818 27.2 14.9 30.7818 14.9 35.2V36.8C14.9 37.6837 14.1837 38.4 13.3 38.4C12.4163 38.4 11.7 37.6837 11.7 36.8V35.2Z"
          fill="#0349A8" />
      </svg>
      <div>{{ $t('Add Employee') }} </div>
    </div>
    <Form ref="myForm" id="add_emp" class="form-contact row mt-3" @submit="AddEmployee" :validation-schema="schema">
      <div class="col-12 col-md-6 mb-3">
        <label class="label-feild" for="name">{{ $t('Employee Name') }}</label>
        <Field class="contact-field" name="name" type="text" :placeholder="$t('Employee Name')" />
        <ErrorMessage name="name" class="error-message" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="label-feild" for="Profession">{{ $t('Profession') }}</label>
        <Field class="contact-field" name="Profession" type="text" :placeholder="$t('Profession')" />
        <ErrorMessage name="Profession" class="error-message" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <h3>
          {{
    this.$i18n.locale == 'ar' ?
      `اختر الجنس`
      :
      `Select Gender`
  }}
        </h3>
        <div class="d-flex mt-2">
          <div class="d-flex align-items-center" style="margin-inline-end: 20px;">
            <Field value="male" checked type="radio" name="gender" />
            <label style="background-color: transparent;color: #0349A8;margin-inline-start: 5px;" for="">{{ this.$i18n.locale == 'ar' ? 'ذكر' : 'Male' }}</label>
          </div>
          <div class="d-flex align-items-center">
            <Field value="female" type="radio" name="gender" />
            <label  style="background-color: transparent;color: #0349A8;margin-inline-start: 5px;" for="">{{ this.$i18n.locale == 'ar' ? 'انثى' : `Female` }}</label>
          </div>
        </div>
        <ErrorMessage name="gender" class="error-message" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="label-feild" for="email">{{ $t('Email') }}</label>
        <Field class="contact-field" name="email" type="email" :placeholder="$t('Email')" />
        <ErrorMessage name="email" class="error-message" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="label-feild" for="name">{{ $t('PhoneNumber') }}</label>
        <input id="phone" type="tel" name="phone">
        <p id="output" class="error-message mt-1 mb-1"></p>
      </div>
      <div class="col-12 col-md-6  mb-3">
        <label class="label-feild" for="name">{{ $t('City') }}</label>
        <Field name="city" v-slot="{ field }">
          <multiselect open-direction="bottom" v-model="value" @select="PickId" v-bind="field" :options="cities" :searchable="false"
            :placeholder="this.$i18n.locale == 'ar' ? 'اختر المدينة' : 'Choose the city'" label="name" track-by="name"
            selectLabel="" :selectedLabel="this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'"
            :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'">
          </multiselect>
        </Field>
        <ErrorMessage name="city" class="error-message" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="label-feild" for="name">{{ $t('Password') }}</label>

        <Field name="password" class="contact-field" type="text" id="password" :placeholder="$t('Password')" />
        <ErrorMessage name="password" class="error-message" />

      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="label-feild" for="name">{{ $t('Confirm_Password') }}</label>
        <Field name="ConfirmPassword" class="contact-field" type="text" id="showConfirmPassword"
          :placeholder="$t('Confirm_Password')" />
        <ErrorMessage name="ConfirmPassword" class="error-message" />
      </div>
      <div class="col-12 mb-3">
        <label class="label-feild">{{ $t('Select Employee Role') }}</label>
        <div class="d-flex felx-wrap">
          <div class="mb-3" v-for="item of Role" :key="item.id">
            <Field :value="item.name" name="Employee_Role" type="radio" :id="`role-${item.id}`" hidden />
            <label class="label-radio" :for="`role-${item.id}`">{{ $i18n.locale === 'ar' ? item.show_name.ar : item.show_name }}</label>
          </div>
        </div>
        <ErrorMessage name="Employee_Role" class="error-message" />
      </div>

<!--      <div class="footer-card">-->
<!--        <button class="btn-third border-0" @click="ClearAll">{{ $t('Clear_all') }}</button>-->
<!--        <button class="btn-main secondary" type="submit">{{ $t('Submit Order') }}</button>-->
<!--      </div>-->
    </Form>
    <template v-slot:footer-btn>
      <button class="btn-third border-0 mx-2" @click="ClearAll">{{ $t('Clear_all') }}</button>
      <button class="btn-main secondary" form="add_emp" type="submit">{{ $t('Submit Order') }}</button>
    </template>
  </Modal>
<!--  </div>-->
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal';
const cookie = Cookie();
import Multiselect from 'vue-multiselect';
import intlTelInput from "../../../../intl-tel-input"
import Modal from '../../../modal/Modal'

export default {
  data() {
    return {
      FullNumber: '',
      cities: [],
      Role: [],
      value: '',
      city_Id: '',
    }
  },
  props: {

  },
  setup() {
    configure({
      validateOnInput: true,
    });

    const schema = yup.object({
      name: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل الاسم مطلوب' : 'name required').min(4, cookie.get('lang') == 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),
      Profession: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل الاسم مطلوب' : 'name required').min(4, cookie.get('lang') == 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),
      gender: yup
        .string()
        .required(cookie.get('lang') === 'ar' ? 'يجب اختيار دور للموظف' : 'Employee role is required'),
      email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الإلكتروني مطلوب' : 'email is required').email(cookie.get('lang') == 'ar' ? 'يجب إدخال بريد إلكتروني صالح' : 'email must be valid'),
      city: yup.object().required(cookie.get('lang') == 'ar' ? 'اختيار المدينة مطلوب' : 'City required'),
      Employee_Role: yup
        .string()
        .required(cookie.get('lang') === 'ar' ? 'يجب اختيار دور للموظف' : 'Employee role is required'),
      password: yup.string()
        .required(cookie.get('lang') === 'ar' ? 'كلمة المرور مطلوبة' : 'Password is required')
        .min(8, cookie.get('lang') === 'ar' ? 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل' : 'Password must be at least 8 characters')
        .matches(/[A-Z]/, cookie.get('lang') === 'ar' ? 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل' : 'Password must contain at least one uppercase letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, cookie.get('lang') === 'ar' ? 'يجب أن تحتوي كلمة المرور على رمز واحد على الأقل' : 'Password must contain at least one symbol'),

      ConfirmPassword: yup.string()
        .required(cookie.get('lang') === 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required')
        .oneOf([yup.ref('password')], cookie.get('lang') === 'ar' ? 'يجب أن تكون كلمة المرور مطابقة' : 'password must be valid'),
    });
    return {
      schema,

    }
  },
  components: {
    Modal,
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  methods: {
    close() {
      this.$emit('hide')
    },
    PickId() {
      this.city_Id = this.value.id;
    },
    ClearAll() {
      this.$refs.myForm.resetForm();
      this.value = '';
      this.city_Id = '';
      this.FullNumber = '';
    },
    AddEmployee(values) {
      if (this.FullNumber) {
        const FData = new FormData();
        FData.append('name', values.name);
        FData.append('email', values.email);
        FData.append('password', values.password);
        FData.append('password_confirmation', values.ConfirmPassword);
        FData.append('gender', values.gender);
        FData.append('profession', values.Profession);
        FData.append('phone', this.FullNumber);
        FData.append('subscribed_latest_news', 1);
        FData.append('fcm_token', 'test');
        FData.append('city_id', this.city_Id);
        FData.append('role', values.Employee_Role);
        axios
          .post(`/company/dashboard/employees`, FData, {
            headers: {
              "Authorization": `Bearer ${cookie.get('CompanyToken')}`
            }
          })
          .then((res) => {
            if (res.status == 200) {
              notify({
                type: "success",
                text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'The request has been sent successfully.',
              });
              this.$emit('hide');
            }
          })
          .catch(function (error) {
            notify({
              type: "error",
              text: error.response.data.message,
            });
          });
      }

    },
    GetRoles() {
      axios.get(`/company/dashboard/roles`, {
        headers: {
          "Authorization": `Bearer ${cookie.get('CompanyToken')}`
        }
      })
        .then((res) => {
          this.Role = res.data.data.Role;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.GetRoles();
    this.$store.dispatch('getCities').then(r => {
      this.cities = this.$store.state.cities;
    });
    const output = document.querySelector("#output");
    const inputElement = document.querySelector('#phone');

    const iti = intlTelInput(inputElement, {
      i18n: {
        searchPlaceholder: cookie.get('lang') ? 'بحث' : 'Search',
      },
      initialCountry: "auto",
      nationalMode: true,
      // geoIpLookup: callback => {
      //   fetch("https://ipapi.co/json")
      //     .then(res => res.json())
      //     .then(data => callback(data.country_code))
      //     .catch(() => callback("us"));
      // },
      geoIpLookup: callback => {
        fetch("https://ipapi.co/country")
          .then(res => {
            if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    return res.text();
          })
          .then(data => callback(data))
          .catch(() => callback("us"));

      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js"
    });

    const handleChange = () => {
      let text;
      if (cookie.get('lang') == 'ar') {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء اعد المحاولة";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
        } else {
          text = "الرجاء ادخال رقم صحيح";
        }
      }
      else {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
          else {
            this.FullNumber = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('keyup', handleChange);
  }
};
</script>

<style scoped>
  .error-message {
    font-size: 14px;
  }
</style>
